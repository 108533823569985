import './online-booking-page-cust-info.css';
import { Slot, CaseInfo, SalesChannel } from '../../../dto/model';
import { Button, Tag, Tooltip, Typography, message } from 'antd';
import {
  CalendarOutlined,
  CarryOutOutlined,
  CompassOutlined,
  EditOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  ShareAltOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { strings } from '../../../lang';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import CustomerInfoEditModal from '../modal/customer-info-edit-modal';
import { useState } from 'react';
import { calculateHowLongCustomerHasBeenAdded } from '../../../utils/inbound-utils';
const salesOsRootUrl = (process as any).env.REACT_APP_SALESOS_BASE_URL;
const salesForceRootUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;

type CustomerInfoSectionProps = {
  caseInfo: CaseInfo;
  setCaseInfo?: (caseInfo: CaseInfo) => void;
  cancelledSlot: Slot | null;
  showWrongPhoneNumberModal: (val: boolean) => void;
  inboundCenter?: boolean | undefined;
};

const CustomerInfoSection = ({ caseInfo, setCaseInfo, cancelledSlot, showWrongPhoneNumberModal, inboundCenter }: CustomerInfoSectionProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  //For now we forward a user to salesforce when they want to edit the customer info
  const openSalesforceCustomerLink = () => {
    if (caseInfo.salesChannel === 'FieldSales') {
      window.open(`${salesOsRootUrl}/opportunities/${caseInfo.opportunityId}`);
    } else {
      setIsEditModalOpen(true);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(caseInfo.salesChannel === 'FieldSales' ? `${salesOsRootUrl}/opportunities/${caseInfo.opportunityId}` : `${salesForceRootUrl}/lightning/r/Opportunity/${caseInfo.opportunityId}/view`)
      .then(() => {
        message.success(strings.customerInfoCaseCopied);
      })
      .catch(() => {
        message.error(strings.customerInfoFailedToCopy);
      });
  };

  const shouldDisplayVoiceMessageHint = caseInfo.callCounter === 0 || caseInfo.callCounter === 6;

  const displayLastScheduledCall = caseInfo.bookingStatus === 'ReschedulingNeeded';

  const calculateLostPeriod = (creationDate: Date | null) => {
    const createdDate = creationDate ? new Date(creationDate) : null;
    if (createdDate) {
      const today = new Date();

      if (today.getDate() === createdDate.getDate() && today.getMonth() === createdDate.getMonth() && today.getFullYear() === createdDate.getFullYear()) {
        return strings.lostPeriod.today;
      } else if (today.getFullYear() === createdDate.getFullYear() && today.getMonth() === createdDate.getMonth() && today.getDate() - createdDate.getDate() === 1) {
        return strings.lostPeriod.yesterday;
      } else if (today.getFullYear() === createdDate.getFullYear() && today.getMonth() === createdDate.getMonth() && today.getDate() - createdDate.getDate() <= 7) {
        return strings.lostPeriod.daysAgo.replace('{0}', (today.getDate() - createdDate.getDate()).toString());
      } else if (today.getFullYear() === createdDate.getFullYear() && today.getMonth() === createdDate.getMonth() && today.getDate() - createdDate.getDate() <= 30) {
        return strings.lostPeriod.weeksAgo.replace('{0}', Math.floor((today.getDate() - createdDate.getDate()) / 7).toString());
      } else if (today.getFullYear() === createdDate.getFullYear() && today.getMonth() - createdDate.getMonth() < 3) {
        return strings.lostPeriod.monthsAgo.replace('{0}', '1');
      } else {
        return strings.lostPeriod.moreThan3MonthsAgo;
      }
    }
  };

  const handleWrongNumberClick = () => {
    showWrongPhoneNumberModal(true);
  };

  return (
    <>
      <section className="bp-section bp-comment bp-customer-section ">
        <div className="bp-customer_icon_container bp-customer-title" style={{ gridArea: '1 / 1 / 2 / 2' }}>
          <UserOutlined /> {caseInfo.salutation} {caseInfo?.firstName} {caseInfo?.lastName}
        </div>

        <div className="bp-customer_icon_container bp-customer-title" style={{ gridArea: '1 / 2 / 2 / 3' }}>
          <a href={`tel:${caseInfo?.phone}`}>
            <PhoneOutlined /> {caseInfo?.phone}
          </a>
          <Tooltip title={strings.wrongPhoneNumber.title}>
            <Button disabled={inboundCenter} className="bp-wrong-phone-number" onClick={handleWrongNumberClick}>
              <WarningOutlined />
            </Button>
          </Tooltip>
        </div>

        <div className="bp-customer-section__buttons" style={{ gridArea: '1 / 3 / 2 / 4' }}>
          <Button onClick={() => setIsEditModalOpen(true)} icon={<EditOutlined />}>
            {strings.edit}
          </Button>
          <Button className="bp-customer-section-copy-btn" icon={<ShareAltOutlined />} onClick={copyToClipboard}>
            {strings.share}
          </Button>
        </div>

        {/* Second row */}
        <div className="bp-customer_icon_container" style={{ gridArea: '2 / 1 / 2 / 2' }}>
          <CalendarOutlined />
          {calculateHowLongCustomerHasBeenAdded(caseInfo?.createdDate)}
        </div>

        <div className="bp-customer_icon_container" style={{ gridArea: '2 / 2 / 3 / 4' }}>
          <MailOutlined /> <span>{caseInfo?.email}</span>
        </div>

        {/* Third row */}
        <div className="bp-customer_icon_container" style={{ gridArea: '3 / 1 / 3 / 2' }}>
          <CarryOutOutlined /> {strings.bookingStatus(caseInfo?.bookingStatus)}
        </div>

        <div className="bp-customer_icon_container" style={{ gridArea: '3 / 2 / 3 / 4' }}>
          <EnvironmentOutlined /> {caseInfo?.street}, {caseInfo?.zipcode} {caseInfo?.city}
        </div>

        {caseInfo.productType && caseInfo.referrer && (
          <div style={{ gridArea: '4 / 1 / 5 / 2' }}>
            {caseInfo.productType && (
              <Tooltip title={strings.toolTipProductType}>
                <Tag color="blue" icon={<CompassOutlined />}>
                  {caseInfo.productType}
                </Tag>
              </Tooltip>
            )}
            {caseInfo.referrer && (
              <Tooltip title={strings.toolTipReferrer}>
                <Tag color="green" icon={<IdcardOutlined />}>
                  Seller: {caseInfo.referrer}
                </Tag>
              </Tooltip>
            )}
          </div>
        )}
        {cancelledSlot && displayLastScheduledCall && (
          <div className="bp-customer-last-app">
            <div style={{ margin: '5px 21px 0', fontStyle: 'italic' }}>
              {strings.lastAppointment(dayjs(cancelledSlot.startDate).format('D MMM YYYY'), dayjs(cancelledSlot.startDate).format('h:mm A'), cancelledSlot.salesManagerName)}
            </div>
          </div>
        )}

        {caseInfo?.salesChannel !== SalesChannel.Revision && shouldDisplayVoiceMessageHint && (
          <div style={{ gridArea: '5 / 1 / 5 / 5', marginRight: 10 }}>
            <Typography>
              <Paragraph className="bp-customer-section-vm-hint">
                <pre style={{ margin: '0' }}>{strings.voiceMailHint}</pre>
              </Paragraph>
            </Typography>
          </div>
        )}
      </section>
      {caseInfo?.salesChannel === SalesChannel.CrmSales && caseInfo?.processingType === 'ReactivatedCustomers' && (
        <section className="bp-section bp-comment" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="bp-customer-lost-reason">
            <Tooltip title={strings.toolTipReactivatedCustomer}>
              <Tag color="blue">{strings.reactivatedCustomer}</Tag>
            </Tooltip>
          </div>
        </section>
      )}
      {caseInfo?.salesChannel === SalesChannel.Revision && (
        <section className="bp-section bp-comment" style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold', margin: '5px 0' }}>{calculateLostPeriod(caseInfo?.caseComments?.filter((item) => item.isCustomerLostReason)[0]?.createdOn)}</div>
          {caseInfo?.caseComments?.filter((item) => item.isCustomerLostReason)[0]?.body && (
            <div style={{ marginBottom: '5px' }}>"{caseInfo?.caseComments?.filter((item) => item.isCustomerLostReason)[0]?.body}"</div>
          )}
          <div>
            {caseInfo?.caseComments?.filter((item) => item.isCustomerLostReason)[0] && (
              <div className="bp-customer-lost-reason">
                {caseInfo?.caseComments
                  ?.filter((item) => item.isCustomerLostReason)[0]
                  ?.looseReasons?.split('\n')
                  .map((item, key) => (
                    <Tag color="volcano">{strings.lostReason(item)}</Tag>
                  ))}
              </div>
            )}
          </div>
        </section>
      )}

      <CustomerInfoEditModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} caseInfo={caseInfo} setCaseInfo={setCaseInfo!} />
    </>
  );
};

export default CustomerInfoSection;
