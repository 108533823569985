//import { faker } from '@faker-js/faker';
import { report } from 'process';
import { LeaderboardDto, UserStatsDto, RatingDto, UserAction } from '../dto/reporting-models';

const reportBaseUrl = (process as any).env.REACT_APP_REPORT_API_ROOT_URL;

export const reportingService = {
  async getLastActionsAsync(accessToken: string): Promise<UserStatsDto> {
    const url = `${reportBaseUrl}/api/v1/profiles/self/actions`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });

    const result = await response.json();
    return result;
  },

  async getTopByExperienceAsync(accessToken: string): Promise<RatingDto> {
    const url = `${reportBaseUrl}/api/v1/leaderboards/experience`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getUserDataAsync(trimmed: boolean, accessToken: string): Promise<UserStatsDto> {
    const url = trimmed ? `${reportBaseUrl}/api/v1/profile?isTrimmed=true` : `${reportBaseUrl}/api/v1/profile`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async getLeaderboardDataAsync(accessToken: string): Promise<LeaderboardDto> {
    const url = `${reportBaseUrl}/api/v1/leaderboard`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },
};
