import LocalizedStrings from 'react-localization';

const germanToEnglish = {
  'Besitzt bereits eine PV Anlage': 'Owns PV system',
  'Kein Eigentümer': 'Not an owner',
  'Dach über 3 Monate fertig': 'Roof over 3 months finished',
  'Kunde möchte nicht mehr kontaktiert werden': 'Customer does not want to be contacted again',
  'Sonstiges (bitte im Kommentar angeben)': 'Other (please specify in comment)',
  'Nicht mehr interessiert': 'Not interested anymore',
  'Möchte nicht an einem Videoanruf teilnehmen': 'Does not want to take part in a video call',
  'Konnte keinen passenden Termin finden': 'Could not find a suitable appointment',
  'Technisch nicht machbar': 'Technically not feasible',
  'Kein Wohnsitz': 'No residence',
  Duplikat: 'Duplicate',
  'Kein Wifi/Laptop': 'No wifi/laptop',
  'Haus ist vermietet': 'Place is rented out',
  Mehrfamilienhaus: 'Apartment building (Mehrfamilienhaus)',
};
const englishToGerman = Object.fromEntries(Object.entries(germanToEnglish).map(([key, value]) => [value, key]));

const customerLostShareContactDataOptionsEnToDe = {
  'Permission given': true,
  'Permission NOT given': false,
};
const customerLostShareContactDataOptionsDeToEn = {
  'Kontaktweitergabe genehmigt': true,
  'Kontaktweitergabe NICHT genehmigt': false,
};

export const strings = new LocalizedStrings({
  en: {
    profileRemainingXpTillNextLvl: 'Remaining XP till next level:',
    profileTopExperience: 'Leaderboard',
    profileExpHover: `Booking successful - 10 XP
    Not reached - 1 XP
    Reached, but not booked - 2 XP
    Customer lost - 2 XP
    Revision queue assignment - 2 XP
    Wrong phone number - 2 XP`,

    reportingActivity: 'Activity',
    reportingCalls: 'Calls',
    reportingBookings: 'Bookings',
    reportingBookingRate: 'Booking Rate (If Reached)',
    reportingShowRate: 'Show Rate',
    reportingVsYesterday: 'v.s. yesterday',
    reportingCallPerHour: 'Calls per Hour Today',
    reportingTopBookersToday: 'Top Bookers Today',
    reportingTopCallersToday: 'Top Callers Today',
    reportingHighestBookingRateToday: 'Highest Booking Rate Today (in %)',
    reportingShowRateLeaderboard: 'Show Rate for Today (in %)',
    reportingComparedToSameTimeYesterday: 'Compared to same time yesterday',
    reportingYourPosition: 'Your Position',
    reportingWarningMessage: 'You have already given more than 3 assigned customers back into the queue without editing them this hour',
    reportingNextUpdateAt: 'Next update at',
    reportingNoAppointmentsToday: 'No appointments today',
    reportingAppointmentsToGo: 'Appointments to go',
    reportingAppointmentsConsidered: 'Appointments considered',
    reportingShowRateClarification: 'There should be at least 3 appointments for the day that already happened to calculate the show rate',
    reportingNotificationCustomerShowed: 'Congrats! Your booked appointment just took place!',
    reportingNotificationCustomerShowedTitle: 'Customer showed up!',
    reportingNotificationActionTitle: 'XP gained!',
    reportingNotificationLevelUp: 'You have reached the level {0} {1}',

    planningOnHoldTag: 'Planning On Hold',
    customerPortalCancellation: 'Customer cancelled via Inbound or SST',
    planningOnHoldExplanation:
      'Planning for this customer could not yet be completed, likely because the house could not be found via Google Maps. The customer should have received an e-mail from the planning department to provide additional details, such as building plans of their house.',
    lostReason: (reason: string) => {
      return reason;
    },
    inboundCallBack: {
      success: 'Your changes have been saved successfully',
      failure: 'There was an error while saving your changes. Please try again.',
    },
    cancelAppointment: 'Cancel Appointment',
    cancelModal: {
      text: 'You are about to cancel this appointment. This cannot be undone. Are you sure you want to continue?',
      rescheduleInstead: 'Reschedule Instead',
      confirmCancellation: 'Confirm Cancellation',
    },
    reschedule: 'Reschedule',
    assignToMeAndOpen: 'Assign to me & Open',
    attention: (email: string) => `Caution: This customer is currently assigned to ${email} and will be called shortly`,
    lastAppointment: (date: string, time: string, salesManager: string) => `Initial appointment on ${date} at ${time} with ${salesManager}`,
    wrongPhoneNumber: {
      title: 'Wrong Phone Number',
      prompt: 'Please specify what is wrong about the phone number',
      cancel: 'Cancel',
      saveAndOpenNextCase: 'Save & Open Next Case',
      pleaseSpecifyReason: 'Please specify a reason',
      buttonClarification: 'Please specify a reason to proceed',
      reasons: ['Number looks invalid (e.g. 000000000 or 123456789)', 'No connection under this number', 'Wrong Person (e.g. did not know about Enpal)', 'Other (please specify in comment)'],
    },

    updateWrongPhoneNumber: {
      body: 'This contact has provided a new phone number. Please try contacting them via the new number to continue.',
      cancel: 'Cancel',
      updateWithNewNumber: 'New Number Is Correct',
      updateWithnewNumberTooltip: 'The number will be updated and you will return to the booking mask.',
      newNumberIncorrect: 'New Number Is Also Wrong',
    },

    searchPlaceholder: 'Seach by Name, Last Name, Phone number, e-mail or ID',

    signedupPeriod: {
      today: 'Today',
      yesterday: 'Yesterday',
      daysAgo: 'Signed up {0} days ago',
      weeksAgo: 'Signed up {0} weeks ago',
      monthsAgo: 'Signed up {0} months ago',
      moreThan3MonthsAgo: 'Signed up more than 3 months ago',
    },

    lostPeriod: {
      today: 'Lost today',
      yesterday: 'Lost yesterday',
      daysAgo: 'Lost {0} days ago',
      weeksAgo: 'Lost {0} weeks ago',
      monthsAgo: 'Lost {0} months ago',
      moreThan3MonthsAgo: 'Lost more than 3 months ago',
    },

    recordingInProgress: 'Recording in progress. The recording will be deleted automatically if the customer is not reached.',
    nonStandardSlots: 'Non-standard slots',
    Mo: 'Mo',
    Tu: 'Tu',
    We: 'We',
    Th: 'Th',
    Fr: 'Fr',
    Sa: 'Sa',
    followingSlotWillBeBooked: 'The following slot will be booked',
    date: 'Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    wouldYouLikeToProceed: 'Would you like to proceed?',
    errorOccurred: 'An error has occurred.',
    close: 'Close',
    bookingSuccessful: 'Booking successful!',
    salesManager: 'Sales Manager',
    youCanCloseThisWindow: 'You can now close this window.',
    errorCode: 'Error code',
    cancel: 'Cancel',
    confirm: 'Confirm',
    noSlotsAvailable: 'No slots available on this week',
    dontCloseWhileBooking: "Please don't close this window while booking is in progress",
    customerCannotBeBooked:
      "Please check whether this customer already has an appointment booked. If not, use the old 'Schedule Sales Call' button in Salesforce for this customer and report the case to your team lead.",
    maximEventCountLabel: 'Maximum possible events for booking, considering max appointments per day per seller',
    totalEventCountLabel: 'Total bookable events, no cap',
    totalBookedEventCountLabel: 'Total booked events for the day',
    from: 'From',
    to: 'To',
    showLogs: 'Show Logs',
    fromLargerThanTo: 'From cannot be larger than To',
    searchMax3Days: 'You can only search in a span of 3 days',
    UnauthorizedAccess: 'You are not authorized to access this page',
    team: 'Team',
    reason: 'Reason',
    customerTier: 'Customer Tier',
    result: 'Result',
    status: 'Status',
    rebook: 'Rebook',
    time: 'Time',
    products: 'Products',

    sellerLanguage: 'Seller Language',
    English: 'English',
    German: 'German',
    referredBy: 'Refeferred by',
    paymentModel: 'Payment model',

    SCHEDULING_CASE_NOT_FOUND: "This customer cannot be booked, please contact support. Error details: 'Scheduling Case not found'",
    NO_SLOTS_AVAILABLE: 'This slot is no longer available. Please try with a different time slot.',

    selectAtLeastOneReason: 'Select at least one reason',
    loadCalendar: 'Load Calendar',
    opportunityCommentsPlaceholder: 'Please insert your comments here. They will be automatically saved on case completion.',
    opportunityCommentsError: 'Too many characters',
    saveAndOpenNextCase: 'Save & Open Next Case',
    openNextCase: 'Open Next Case',
    backToInboundCenter: 'Back to Inbound Center',
    modalTextLoadingNextCustomer: 'Loading next customer, hang tight...',
    modalTitleReachedNotBooked: 'Reached, But Not Booked',
    modalTextReachedNotBookedHoursInAdvance: 'Please select the minimum number of hours we should wait before calling the customer again',
    modalTitleCustomerLost: 'Give to Revision',
    looseCustomerButton: 'Customer Lost',
    modalTitleNotReached: 'Not Reached',
    modalTextNotReached: 'This action will snooze the current case and open the next case',
    modalTextSelectNewTime: 'Or select the approximate time when the customer will next be available:',
    modalTextCustomerLostRevision: 'Note: Your are about to set this customer to lost. Please select one or more reasons why a sales call with the customer is currently not possible',
    modalTextCustomerLost: 'Note: You are about to give the customer to the revision team.  Please select one or more reasons why a sales call with the customer is currently not possible',
    customerLostOptions: Object.entries(germanToEnglish).map(([nameOfProp, propValue]) => ({
      value: propValue,
      label: propValue,
    })),
    customerLostPlaceholder: 'Please add your comments here...',
    customerLostUpTo3OptionsError: 'You can only select up to 3 reasons',
    customerLostAtLeastOneOptionError: 'Please select at least one reason',
    customerLostTooManyCharactersError: 'No more than 255 characters allowed.',
    customerLostNoCharactersError: 'Description of why the customer was lost is required.',

    goOnline: 'Go Online',
    goOffline: 'Go Offline',
    goOfflineModalText: 'This will discard any changes you have made to the current case and re-assign it to a different booking agent. Are you sure you want to proceed?',
    goOnlineModalText: 'You are about to get a case assigned. Are you sure you want to proceed?',

    coundownRefreshError: 'Can only refresh the case assignment duration when it has less than 8 minutes remaining',
    countdownRefreshTooltip: 'Refresh the case assignment duration',
    countdownLessThanAMinuteTitle: 'Less than 1 minute remaining on this case',
    countdownLessThanAMinuteDescription: 'You will automatically be unassigned from this case when the timer runs out. Please refresh the timer via the provided button if you need more time.',
    salesScript: 'Sales Script',
    objections: 'Objections',
    appointmentDetails: 'Appointment Details',
    openingNextCaseIn: 'Opening next case in {0}',
    youAreOffline: 'You are offline',
    goBackOnlineToReceiveCase: 'Please go back online to receive a new case',

    offlinePageTitle: 'You are offline',
    offlinePageContent: 'Please go back online to receive a new case.',

    onlinePageNoCasesTitle: 'There are no cases at the moment',
    onlinePageNoCasesContent: 'Re-trying in ',
    onlinePageNoCaesFetchingNewCase: 'Fetching a new case..',
    onlinePageNoCasesPleaseDoNotClose: 'Please do not close this page.',
    onlinePageNoCasesSeconds: ' seconds',

    caseCommentsBtn: (count: number) => `Show ${count} previous comments`,
    caseCommentsModalTitle: 'Previous Comments',
    customerInfoCaseCopied: 'Case copied to clipboard',
    customerInfoFailedToCopy: 'Failed to copy case to clipboard',

    share: 'Share',
    edit: 'Edit',

    cancelCountdown: 'Cancel Countdown',
    toolTipReferrer: 'This sales agent was recommended by an existing Enpal customer. The new customer receives a sales appointment with the same sales agent as the referrer, if possible',
    toolTipProductType: 'This is the product and financing type selected for the customer. The customer will receive an appointment with a sales agent who is trained to sell all selected products.',
    toolTipReactivatedCustomer: 'This customer was reactivated as part of an internal campaign.',
    reactivatedCustomer: 'Reactivated Customer',

    caseCompleteNotification: 'You have been assigned to a new case',
    caseNotFoundErrorDescription: 'This case is no longer available. The customer may have been processed outside BookingOS or booked an appointment themselves.',
    youCanGetANewCase: 'You can get a new case by clicking the button below.',
    genericCloseCaseErrorDescription: 'An error has ocurred when closing this case. This error has been logged.',
    getNewCase: 'Get New Case',

    voiceMailHint: "If you don't reach the customer, please leave a voice message",

    assignNewCaseError: 'Assigning a new case unsuccessful. Please try again.',

    bookingStatus: (status: string) => {
      return status === 'FirstTimeScheduling' || status === 'First Time Scheduling' ? 'First Time Scheduling' : 'Rescheduling Needed';
    },
    salesChannelDropdownLabel: 'Choose your sales channel:',

    noOpportunityInCalendarTitle: 'Customer Not Found',
    noOpportunityInCalendarMessage:
      'Bookings for this opportunity will fail since it is either closed or already in a different step of the sales funnel. Please ensure the opportunity is open and in the scheduling stage in Salesforce.',

    customerLostShareDataPlaceholder: 'Did the customer agree to share their contact details?',
    customerLostShareDataEntries: Object.entries(customerLostShareContactDataOptionsEnToDe).map(([nameOfProp, propValue]) => ({
      value: propValue,
      label: nameOfProp,
    })),
    customerLostShareDataLabel: 'Please ask the customer whether they give consent to share their contact details with an Enpal partner.',
    customerLostShareDataTooltip: `This customer can likely be served by an Enpal partner. By giving their consent, customers allow us to connect them with a partner. Please ensure to also record the customer's response in Talkdesk`,
    customerLostShareDataNoOptionError: 'Please specify whether the customer agreed to share their contact details',

    contactEditTitle: 'Edit Contact Data',
    contactEditCancel: 'Cancel',
    contactEditSave: 'Save',
    contactEditSalutation: 'Salutation',
    contactEditFirstName: 'First Name',
    contactEditLastName: 'Last Name',
    contactEditPhone: 'Phone Number',
    contactEditAddress: 'Adress',
    contactEditStreet: 'Street',
    contactEditZipCode: 'Zipcode',
    contactEditCity: 'City',
    contactEditConvertToAreaCode: 'Add German Area Code (+49)',
    contactEditEditInSalesforce: 'Edit Address',
    contactEditNamesInSalesforce: 'Edit Name',
    customerLostDataDeletion: 'Data Deletion',
    customerLostConfirmEmailSent: 'I confirm that I have sent an e-mail to request data deletion',
    customerLostDataDeletionProceedToolTip: 'Please confirm that you have sent an e-mail to request data deletion.',
    customerLostDataDeletionButtonToolTip: `Generate an e-mail draft to the GDPR team for deleting this customer's data. You can edit the e-mail before sending it.`,
  },
  de: {
    profileRemainingXpTillNextLvl: 'Verbleibende XP bis zum nächsten Level:',
    profileTopExperience: 'Bestenliste',
    profileExpHover: `Gebucht - 10 XP
    Nicht erreicht - 1 XP
    Erreicht, aber nicht gebucht - 2 XP
    Kunde verloren - 2 XP
    An Revision gegeben - 2 XP
    Falsche Telefonnummer - 2 XP`,

    reportingActivity: 'Aktivität',
    reportingCalls: 'Anrufe',
    reportingBookings: 'Buchungen',
    reportingBookingRate: 'Buchungsrate (Wenn Erreicht)',
    reportingShowRate: 'Show Rate',
    reportingVsYesterday: 'ggü. gestern',
    reportingCallPerHour: 'Anrufe pro Stunde Heute',
    reportingTopBookersToday: 'Top Bucher Heute',
    reportingTopCallersToday: 'Top Anrufer Heute',
    reportingHighestBookingRateToday: 'Höchste Buchungsrate Heute (in %)',
    reportingShowRateLeaderboard: 'Show Rate Heute (in %)',
    reportingComparedToSameTimeYesterday: 'Im Vergleich zur gleichen Zeit gestern',
    reportingYourPosition: 'Deine Position',
    reportingWarningMessage: 'Du hast diese Stunde bereits mehr als 3 zugewiesene Kunden zurück in die Warteschlange gegeben, ohne sie zu bearbeiten',
    reportingNextUpdateAt: 'Nächstes Update um',
    reportingNoAppointmentsToday: 'Keine Termine Heute',
    reportingAppointmentsToGo: 'Verbleibende Termine',
    reportingAppointmentsConsidered: 'Berücksichtigte Termine',
    reportingShowRateClarification: 'Es sollten mindestens 3 Termine für den Tag vorhanden sein, die bereits stattgefunden haben, um die Show Rate zu berechnen',
    reportingNotificationCustomerShowed: 'Glückwunsch! Dein gebuchter Termin hat gerade stattgefunden!',
    reportingNotificationCustomerShowedTitle: 'Kunde zum Termin aufgetaucht!',
    reportingNotificationActionTitle: 'XP erhalten!',
    reportingNotificationLevelUp: 'Du hast jetzt das Level {0} {1} erreicht',

    planningOnHoldTag: 'Planung pausiert',
    customerPortalCancellation: 'Kunde hat per Inbound oder SST abgesagt',
    planningOnHoldExplanation:
      'Die Planung für diesen Kunden konnte noch nicht abgeschlossen werden, womöglich weil das Haus nicht bei Google Maps gefunden wurde. Der Kunde sollte eine E-Mail von unsere Planungsabteilung erhalten haben, in welcher nach zusätzlichen Infos wie Bauplänen des Hauses gefragt wurde.',
    lostReason: (reason: string) => {
      return englishToGerman[reason];
    },
    inboundCallBack: {
      success: 'Deine Änderungen wurden gespeichert',
      failure: 'Beim Speichern deiner Änderungen ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    },
    cancelAppointment: 'Termin Absagen',
    cancelModal: {
      text: 'Du bist dabei, den Termin endgültig abzusagen. Bist du sicher, dass du fortfahren möchtest?',
      rescheduleInstead: 'Stattdessen Umbuchen',
      confirmCancellation: 'Termin Absagen',
    },
    backToInboundCenter: 'Zurück zu Inbound Center',
    reschedule: 'Umbuchen',
    assignToMeAndOpen: 'Mir zuweisen & Öffnen',
    searchPlaceholder: 'Suchen Sie nach Vorname, Nachname, Telefonnummer, E-Mail oder ID',
    attention: (email: string) => `Achtung! Dieser Kunde ist derzeit ${email} zugewiesen und wird in Kürze angerufen`,
    lastAppointment: (date: string, time: string, salesManager: string) => `Ursprünglicher Termin am ${date} um ${time} mit ${salesManager}`,
    wrongPhoneNumber: {
      title: 'Falsche Telefonnummer',
      prompt: 'Bitte wähle aus, was an der Telefonnummer falsch ist',
      cancel: 'Absagen',
      saveAndOpenNextCase: 'Speichern & Nächsten Fall öffnen',
      pleaseSpecifyReason: 'Bitte gib einen Grund an',
      buttonClarification: 'Bitte gib einen Grund an um fortzufahren',
      reasons: [
        'Nummer sieht falsch aus (z.B. 000000000 oder 123456789)',
        'Kein Anschluss unter dieser Nummer',
        'Falsche Person (z.B. wenn die Person Enpal nicht kennt)',
        'Andere (bitte unten angeben)',
      ],
    },
    updateWrongPhoneNumber: {
      body: 'Dieser Kontakt hat eine neue Telefonnummer angegeben. Bitte probier den Kunden über die neue Nummer zu erreichen, um fortzufahren.',
      cancel: 'Abbrechen',
      updateWithNewNumber: 'Neue Nummer ist richtig',
      updateWithnewNumberTooltip: 'Die Nummer wird aktualisiert und du gelangst zurück zur Buchungsmaske',
      newNumberIncorrect: 'Neue Nummer ist auch falsch',
    },

    signedupPeriod: {
      today: 'Heute',
      yesterday: 'Gestern',
      daysAgo: 'Vor {0} Tagen angemeldet',
      weeksAgo: 'Vor {0} Wochen angemeldet',
      monthsAgo: 'Vor {0} Monaten angemeldet',
      moreThan3MonthsAgo: 'Vor mehr als 3 Monaten angemeldet',
    },

    lostPeriod: {
      today: 'Heute gelostet',
      yesterday: 'Gestern gelostet',
      daysAgo: 'Vor {0} Tagen gelostet',
      weeksAgo: 'Vor {0} Wochen gelostet',
      monthsAgo: 'Vor {0} Monaten gelostet',
      moreThan3MonthsAgo: 'Vor mehr als 3 Monaten gelostet',
    },

    recordingInProgress: 'Dieser Anruf wird aufgenommen. Falls der Kunde nicht erreicht wird, wird die Aufnahme automatisch gelöscht.',
    nonStandardSlots: 'Unübliche slots',
    Mo: 'Mo',
    Tu: 'Di',
    We: 'Mi',
    Th: 'Do',
    Fr: 'Fr',
    Sa: 'Sa',
    followingSlotWillBeBooked: 'Folgender Slot wird gebucht',
    date: 'Datum',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    wouldYouLikeToProceed: 'Möchtest Du fortfahren?',
    errorOccurred: 'Ein Fehler ist aufgetreten.',
    close: 'Schließen',
    bookingSuccessful: 'Buchung erfolgreich!',
    salesManager: 'Sales Manager',
    youCanCloseThisWindow: 'Du kannst dieses Fenster nun schließen.',
    errorCode: 'Fehlercode',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    noSlotsAvailable: 'Für diese Woche sind keine Slots verfügbar',
    dontCloseWhileBooking: 'Bitte schließe dieses Fenster nicht, während die Buchung läuft',
    customerCannotBeBooked:
      "Bitte überprüfe, ob dieser Kunde bereits einen Termin gebucht hat. Wenn nicht, verwende die alte Schaltfläche 'Verkaufsgespräch planen' in Salesforce für diesen Kunden und melde diesen Fall Deinem Teamleiter.",
    maximEventCountLabel: 'Maximal mögliche Ereignisse für die Buchung, unter Berücksichtigung der maximalen Termine pro Tag und Verkäufer',
    totalEventCountLabel: 'Buchbare Ereignisse insgesamt, ohne Obergrenze',
    totalBookedEventCountLabel: 'Gebuchte Veranstaltungen für den Tag insgesamt',
    from: 'Von',
    to: 'Bis',
    showLogs: 'Logs Anzeigen',
    fromLargerThanTo: 'Von darf nicht größer als Bis sein',
    searchMax3Days: 'Du kannst nur innerhalb von 3 Tagen suchen',
    UnauthorizedAccess: 'Du bist nicht autorisiert auf diese Seite zuzugreifen',
    team: 'Team',
    reason: 'Grund',
    customerTier: 'Kundenrang',
    result: 'Ergebnis',
    status: 'Status',
    rebook: 'Umbuchen',
    time: 'Zeit',
    sellerLanguage: 'Sales Manager Sprache',
    English: 'Englisch',
    German: 'Deutsch',
    referredBy: 'Empfohlen von',
    paymentModel: 'Zahlungsmodell',
    products: 'Produkte',

    SCHEDULING_CASE_NOT_FOUND: 'Dieser Slot/Kunde konnte nicht gebucht werden: Bitte schick den Kundenlink an Deinen TL und Carl Löbbecke und nutze den alten Button in Salesforce zur Buchung.',
    NO_SLOTS_AVAILABLE: 'Dieser Slot ist nicht mehr verfügbar. Bitte versuche es mit einem anderen Zeitfenster.',

    selectAtLeastOneReason: 'Wähle mindestens einen Grund aus',
    loadCalendar: 'Kalender Laden',
    opportunityCommentsPlaceholder: 'Füge hier Deine Gesprächsnotizen ein. Sie werden automatisch beim Abschluss des Falls gespeichert.',
    opportunityCommentsError: 'Zu viele Zeichen',
    saveAndOpenNextCase: 'Speichern & Nächsten Fall öffnen',
    openNextCase: 'Nächsten Fall öffnen',
    modalTextLoadingNextCustomer: 'Nächster Kunde wird geladen...',
    modalTitleReachedNotBooked: 'Erreicht, Aber Nicht Gebucht',
    modalTextReachedNotBookedHoursInAdvance: 'Bitte wähle die Anzahl an Stunden, die wir mindestens warten sollen, bevor wir den Kunden erneut anrufen',
    modalTitleCustomerLost: 'An Revision geben',
    looseCustomerButton: 'Kunde verloren',
    modalTitleNotReached: 'Nicht Erreicht',
    modalTextNotReached: 'Mit dieser Aktion wird der aktuelle Fall gesnoozed und der nächste Fall geöffnet.',
    modalTextSelectNewTime: 'Oder wähle die ungefähre Zeit, zu der der Kunde das nächste Mal erreichbar ist:',
    modalTextCustomerLostRevision:
      'Hinweis: Du bist im Begriff, den Kunden zu losten. Bitte wähle einen oder mehrere Gründe aus, warum ein Verkaufsgespräch mit dem Kunden derzeit nicht durchführbar ist',
    modalTextCustomerLost:
      'Hinweis: Du bist im Begriff, den Kunden an das Revision Team zu geben. Bitte wähle einen oder mehrere Gründe aus, warum ein Verkaufsgespräch mit dem Kunden derzeit nicht durchführbar ist',
    customerLostOptions: Object.entries(germanToEnglish).map(([nameOfProp, propValue]) => ({
      value: propValue,
      label: nameOfProp,
    })),
    customerLostPlaceholder: 'Bitte füge hier Deine Kommentare ein...',
    customerLostUpTo3OptionsError: 'Du kannst maximal 3 Gründe auswählen',
    customerLostAtLeastOneOptionError: 'Bitte wähle mindestens einen Grund aus',
    customerLostTooManyCharactersError: 'Es sind nicht mehr als 255 Zeichen erlaubt.',
    customerLostNoCharactersError: 'Eine Beschreibung, warum der Kunde verloren gegangen ist, ist erforderlich.',

    goOnline: 'Online Gehen',
    goOffline: 'Offline Gehen',
    goOfflineModalText:
      'Dadurch werden alle Änderungen, die Du am aktuellen Fall vorgenommen hast, verworfen und der Fall wird einem anderen Buchungsagenten neu zugewiesen. Bist Du sicher, dass Du fortfahren möchtest?',
    goOnlineModalText: 'Dir wird gleich ein Fall zugewiesen. Bist Du sicher, dass Du fortfahren möchtest?',

    coundownRefreshError: 'Die Dauer der Fallzuweisung kann nur aktualisiert werden, wenn sie weniger als 8 Minuten beträgt',
    countdownRefreshTooltip: 'Aktualisiere die Dauer der Fallzuweisung',
    countdownLessThanAMinuteTitle: 'Weniger als 1 Minute verbleibt für diesen Fall',
    countdownLessThanAMinuteDescription:
      'Nach Ablauf des Timers wird dieser Fall automatisch einer anderen Person zugewiesen. Wenn Du mehr Zeit brauchst, kannst Du den Timer über den Knopf daneben verlängern.',
    salesScript: 'Verkaufsskript',
    objections: 'Einwände',
    appointmentDetails: 'Termindetails',
    openingNextCaseIn: 'Nächster Fall wird in {0}',
    youAreOffline: 'Du bist offline',
    goBackOnlineToReceiveCase: 'Bitte geh wieder online, um einen neuen Fall zu erhalten',

    offlinePageTitle: 'Du bist offline',
    offlinePageContent: 'Bitte geh online, um einen neuen Fall zu erhalten.',

    onlinePageNoCasesTitle: 'Es gibt im Moment keine Fälle',
    onlinePageNoCasesContent: 'Erneuter Versuch in ',
    onlinePageNoCaesFetchingNewCase: 'Einen neuen Fall holen..',
    onlinePageNoCasesPleaseDoNotClose: 'Bitte schließe diese Seite nicht.',
    onlinePageNoCasesSeconds: ' Sekunden',
    caseCommentsBtn: (count: number) => `Zeige ${count} vorherige Kommentare`,
    caseCommentsModalTitle: 'Vorherige Kommentare',

    customerInfoCaseCopied: 'Fall in die Zwischenablage kopiert',
    customerInfoFailedToCopy: 'Text konnte nicht kopiert werden',

    share: 'Teilen',
    edit: 'Bearbeiten',

    cancelCountdown: 'Countdown abbrechen',
    toolTipReferrer:
      'Dieser Vertriebsmitarbeiter wurde von einem bestehenden Enpal Kunden empfohlen. Der neue Kunde erhält einen Vertriebstermin mit dem gleichen Mitarbeiter wie der Empfehler, sofern dies möglich ist.',
    toolTipProductType:
      'Dies ist die für den Kunden ausgewählte Produkt- und Finanzierungsart. Der Kunde erhält einen Vetriebstermin mit einem Mitarbeiter, der für den Verkauf aller ausgewählten Produkte geschult ist.',
    toolTipReactivatedCustomer: 'Dieser Kunde wurde über eine interne Kampagne reaktiviert.',
    reactivatedCustomer: 'Reaktivierter Kunde',

    caseCompleteNotification: 'Dir wurde ein neuer Fall zugewiesen',

    caseNotFoundErrorDescription: 'Diese Fall ist nicht mehr verfügbar. Womöglich wurde der Kunde außerhalb von BookingOS bearbeitet oder hat sich selbst einen Termin gebucht.',
    youCanGetANewCase: 'Um einen neuen Fall zu erhalten, klicke auf die Schaltfläche unten.',
    genericCloseCaseErrorDescription: 'Beim Schließen dieses Falles ist ein Fehler aufgetreten. Dieser Fehler wurde gespeichert.',
    getNewCase: 'Neuen Fall erhalten',

    voiceMailHint: 'Wenn Du diesen Kunden nicht erreichst, hinterlasse bitte eine Mailbox-Nachricht.',

    assignNewCaseError: 'Zuweisung eines neuen Falls fehlgeschlagen. Bitte versuchen Sie es erneut.',

    bookingStatus: (status: string) => {
      return status === 'FirstTimeScheduling' || status === 'First Time Scheduling' ? 'Erstbuchung' : 'Reterminierung';
    },
    salesChannelDropdownLabel: 'Wähle Deinen Vertriebskanal:',

    noOpportunityInCalendarTitle: 'Kunde nicht gefunden',
    noOpportunityInCalendarMessage:
      'Buchungen für diese Opportunity werden fehlschlagen, da sie entweder geschlossen wurde oder bereits weiter im Verkaufsprozess ist. Bitte stell sicher, dass die Opportunity in Salesforce offen ist und sich in der Buchungsphase befindet',

    customerLostShareDataPlaceholder: 'Hat der Kunde der Kontaktweitergabe an Enpal-Partner zugestimmt?',
    customerLostShareDataEntries: Object.entries(customerLostShareContactDataOptionsDeToEn).map(([nameOfProp, propValue]) => ({
      value: propValue,
      label: nameOfProp,
    })),
    customerLostShareDataLabel: 'Bitte frag den Kunden, ob er mit der Weitergabe seiner Kontaktdaten an einen Enpal Partner einverstanden ist.',
    customerLostShareDataTooltip:
      'Dieser Kunde kann sein Bauvorhaben wahrscheinlich durch einen Enpal Partner realisieren. Indem er seine Zustimmung gibt, können wir ihn mit einem entsprechenden Partner in Verbindung setzen. Bitte achte darauf, dass du die Einverständnis des Kunden auch in Talkdesk aufnimmst.',
    customerLostShareDataNoOptionError: 'Bitte gib an, ob der Kunde der Kontaktweitergabe zustimmt',

    contactEditTitle: 'Kontaktdaten bearbeiten',
    contactEditCancel: 'Abbrechen',
    contactEditSave: 'Speichern',
    contactEditSalutation: 'Anrede',
    contactEditFirstName: 'Vorname',
    contactEditLastName: 'Nachname',
    contactEditPhone: 'Telefonnummer',
    contactEditAddress: 'Adresse',
    contactEditStreet: 'Straße',
    contactEditZipCode: 'Postleitzahl',
    contactEditCity: 'Stadt',
    contactEditConvertToAreaCode: '+49 Vorwahl einfügen',
    contactEditEditInSalesforce: 'Adresse bearbeiten',
    contactEditNamesInSalesforce: 'Namen bearbeiten',
    customerLostDataDeletion: 'Datenlöschung',
    customerLostConfirmEmailSent: 'E-Mail an Datenschutz versendet',
    customerLostDataDeletionProceedToolTip: 'Bitte bestätige, dass du eine E-Mail zur Datenlöschung an das Datenschutz-Team gesendet hast.',
    customerLostDataDeletionButtonToolTip: 'Erstellt einen E-Mail Entwurf an das Datenschutzteam zur Datenlöschung dieses Kundens. Du kannst den Entwurf vor dem Absenden bearbeiten.',
  },
});
