import { useMsal } from '@azure/msal-react';
import { Header } from '../header/header';
import './profile-page.css';
import { Card, Progress, Row, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import RankingCard from '../reporting/ranking-card';
import { strings } from '../../lang';
import { RatingDto, TopBooker, UserStatsDto } from '../../dto/reporting-models';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { useEffect, useState } from 'react';
import { reportingService } from '../../services/reporting-service';

export const ProfilePage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [userProfile, setUserProfile] = useState<UserStatsDto | null>(null);
  const [expRaiting, setExpRaiting] = useState<RatingDto | null>(null);

  useEffect(() => {
    (async () => {
      const accessToken = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const user = await reportingService.getUserDataAsync(false, accessToken.accessToken);
      const expOrder = await reportingService.getTopByExperienceAsync(accessToken.accessToken);
      setExpRaiting(expOrder);
      setUserProfile(user);
    })();
  }, [instance, inProgress]);

  const calculateReceivedXpPercentage = () => {
    return +((userProfile?.currentXp! / userProfile?.nextLevelXp!) * 100).toFixed(1);
  };

  return (
    <>
      <Header />
      <div className="profile-page">
        <h2 className="profile-title">{userProfile?.fullName}</h2>
        <div className="profile-info">
          <Row gutter={8}>
            <div style={{ marginRight: 20, width: 800 }}>
              <Card className="activity-card" style={{ maxHeight: 'fit-content', margin: '0 0 20px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ margin: '0 0 20px 0' }}>
                    Level {userProfile?.currentLevel} {userProfile?.levelName}
                  </h3>

                  <div className="progress-part">
                    <Tooltip title={`${strings.profileRemainingXpTillNextLvl} ${userProfile?.nextLevelXp! - userProfile?.currentXp!}`}>
                      <Progress strokeColor="black" percent={calculateReceivedXpPercentage()} percentPosition={{ align: 'center', type: 'inner' }} />
                    </Tooltip>
                    <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={strings.profileExpHover}>
                      <QuestionCircleOutlined style={{ marginLeft: 10, fontSize: 34 }} />
                    </Tooltip>
                  </div>
                </div>
              </Card>
              <Card className="activity-card"></Card>
            </div>
          </Row>
          <Row>
            <RankingCard title={strings.profileTopExperience} rankData={expRaiting ?? null} />
          </Row>
        </div>
      </div>
    </>
  );
};
