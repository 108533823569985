import { Button, Modal, Tooltip, notification } from 'antd';
import Countdown, { zeroPad } from 'react-countdown';
import { strings } from '../../../lang';
import { useEffect, useState } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { HasRole, Role } from '../../../utils/auth-utils';
import { useMsal } from '@azure/msal-react';
import SalesChannelDropdown from './sales-channel-dropdown';
import { useSalesChannelState } from '../../../store/header-state';
import { SalesChannel } from '../../../dto/model';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

type HeaderBookingPageProps = {
  isOnline: boolean;
  caseId: string;
  caseExpiration: Date | null | undefined;
  onOnlineChanged: (isOnline: boolean) => Promise<void>;
  onRefreshCountdown: () => Promise<void>;
  inboundCenter?: boolean | undefined;
};

export const HeaderBookingPage = ({ isOnline, caseExpiration, onOnlineChanged, onRefreshCountdown, inboundCenter }: HeaderBookingPageProps) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [countdownError, setCountdownError] = useState<string>();
  const [isAdmin, setIsAdmin] = useState(false);
  const { accounts } = useMsal();
  const [, setSalesChannel, salesChannelLabel] = useSalesChannelState(useShallow((state) => [state.salesChannel, state.setSalesChannel, state.salesChannelLabel]));
  const navigate = useNavigate();

  useEffect(() => {
    if (!accounts || accounts.length === 0) {
      return;
    }
    if (HasRole(accounts, Role.BookingAdmin)) {
      setIsAdmin(HasRole(accounts, Role.BookingAdmin));
    } else {
      HasRole(accounts, Role.CrmSalesRole)
        ? setSalesChannel(SalesChannel.CrmSales)
        : HasRole(accounts, Role.FieldSalesBookingAgent)
          ? setSalesChannel(SalesChannel.FieldSales)
          : HasRole(accounts, Role.RevisionAgent)
            ? setSalesChannel(SalesChannel.Revision)
            : setSalesChannel(SalesChannel.DigitalSales);
    }
  }, [accounts]);

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <span>Re-assigning the case!</span>;
    } else {
      // Render a countdown
      return (
        <span className="header__countdown">
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const refreshCountdown = async () => {
    if (isOnline && caseExpiration) {
      const now = Date.now();
      //check if the onlineTimeout is More than 5 minutes, if it is, show an error message and not refresh it
      if (new Date(caseExpiration!).getTime() - now > 8 * 60 * 1000) {
        setCountdownError(strings.coundownRefreshError);
        setTimeout(() => setCountdownError(''), 5000);
        return;
      }
    }
    await onRefreshCountdown();
  };

  const countdownMinutes = caseExpiration ? new Date().getTime() + new Date(caseExpiration).getTime() - new Date().getTime() : undefined;

  const logOutUser = async () => {
    try {
      await onOnlineChanged(false);
    } catch (e) { }
  };

  const onOnlineChange = async () => {
    try {
      await onOnlineChanged(!isOnline);
      setCountdownError('');
      setOpenConfirmModal(false);
    } catch (e) { }
  };

  const handleTick = ({ total }: any) => {
    const remainingSeconds = total / 1000;
    if (remainingSeconds >= 59 && remainingSeconds < 60) {
      notification.open({
        message: strings.countdownLessThanAMinuteTitle,
        description: strings.countdownLessThanAMinuteDescription,
        duration: 10,
        placement: 'topRight',
        type: 'warning',
      });
    }
  };

  return (
    <>
      <div className="bp-header__status" style={{ marginRight: 10 }}>
        {isOnline && caseExpiration && (
          <>
            {countdownError && <div style={{ color: 'red', marginRight: 15 }}>{countdownError}</div>}
            <Tooltip title={strings.countdownRefreshTooltip}>
              <Button style={{ marginRight: 10 }} icon={<ClockCircleOutlined />} onClick={refreshCountdown} />
            </Tooltip>
            <Countdown onComplete={logOutUser} onTick={handleTick} date={countdownMinutes} renderer={renderer} />
          </>
        )}
        {isOnline && !inboundCenter && <span className="sales-channel-header">{salesChannelLabel}</span>}
        <Button
          className="bp-header__button"
          onClick={async () => {
            if (!inboundCenter) {
              setOpenConfirmModal(true);
            } else {
              await onOnlineChange();
              navigate('/inbound', {
                state: {
                  isSuccess: true,
                },
              });
            }
          }}
        >
          <span className={`bp-header__status-color ${!isOnline && 'bp-header__status-color--offline'}`}></span>
          {isOnline ? (inboundCenter ? strings.backToInboundCenter : strings.goOffline) : strings.goOnline}
        </Button>
      </div>

      <Modal
        title={isOnline ? strings.goOffline : strings.goOnline}
        centered
        closable={false}
        maskClosable={false}
        keyboard={false}
        open={openConfirmModal}
        onOk={onOnlineChange}
        okText={strings.confirm}
        cancelText={strings.cancel}
        onCancel={() => setOpenConfirmModal(false)}
      >
        {isOnline ? strings.goOfflineModalText : strings.goOnlineModalText}
        {!isOnline && isAdmin && !inboundCenter && <SalesChannelDropdown />}
      </Modal>
    </>
  );
};
