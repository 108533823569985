const getRedirectUrl = () => {
  const parts = window.location.href.split("?");

  if (parts.length < 2) {
    return window.location.toString();
  }

  return parts[0];
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID!,
    authority:
      "https://login.microsoftonline.com/d22da9a6-d89a-4764-9d56-96b020869dff",
    redirectUri: getRedirectUrl(),
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const scopes = {
  transcriptionsApi: [
    `api://${process.env.REACT_APP_STT_API_CLIENT_ID}/transcription.view`,
    `api://${process.env.REACT_APP_STT_API_CLIENT_ID}/transcription.restart`,
  ],
  salesAppointmentApi: [
    `api://${process.env.REACT_APP_SALES_APPOINTMENT_API_CLIENT_ID}/slots.book`,
    `api://${process.env.REACT_APP_SALES_APPOINTMENT_API_CLIENT_ID}/slots.view`,
  ],
  teamsDispatcherService: [
    `https://enpal.de/TeamsDispaterService/meetings-report:read`,
  ],
};
