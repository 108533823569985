import { Card, Row, Col, Statistic, Typography, Tooltip, Button } from 'antd';
import './reporting-page.css';
import { Header } from '../header/header';
import { LineChart } from '@mantine/charts';
import RankingCard from './ranking-card';
import { reportingService } from '../../services/reporting-service';
import { useEffect, useState } from 'react';
import { CaretDownOutlined, CaretUpOutlined, ExclamationOutlined, InfoCircleOutlined, MinusOutlined, SyncOutlined } from '@ant-design/icons';
import { LeaderboardDto, RatingDto } from '../../dto/reporting-models';
import dayjs from 'dayjs';
import { useMsal } from '@azure/msal-react';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import { strings } from '../../lang';

const { Title } = Typography;

const ReportingPage = () => {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardDto | null>(null);
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    const fetchData = async () => {
      // get access token
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const leaderboard = await reportingService.getLeaderboardDataAsync(token.accessToken);
      setLeaderboardData(leaderboard);
    };
    fetchData();
  }, []);

  const data = Object.entries(
    leaderboardData?.currentUser?.userActions
      .sort((a, b) => a.when.localeCompare(b.when))
      .filter((action) => dayjs(action.when).isSame(dayjs(), 'day') && ['Booked', 'ReachedNotBooked', 'NotReached', 'CustomerLost'].includes(action.actionType))
      .map((action) => ({
        hour: dayjs(action.when).format('h A'),
        callsPerHour: ['Booked', 'ReachedNotBooked', 'NotReached', 'CustomerLost'].includes(action.actionType) ? 1 : 0,
      }))
      .reduce<Record<string, number>>((acc, curr) => {
        acc[curr.hour] = acc[curr.hour] ? acc[curr.hour] + curr.callsPerHour : 1;
        return acc;
      }, {}) ?? []
  ).map(([hour, callsPerHour]) => ({
    hour,
    callsPerHour,
  }));

  const getHourlyData = (date: dayjs.Dayjs) => {
    return Object.entries(
      leaderboardData?.currentUser?.userActions
        .sort((a, b) => a.when.localeCompare(b.when))
        .filter((action) => dayjs(action.when).isSame(date, 'day'))
        .map((action) => ({
          hour: dayjs(action.when).format('H'),
          callsPerHour: ['Booked', 'ReachedNotBooked', 'CustomerLost'].includes(action.actionType) ? 1 : 0,
          bookingsPerHour: action.actionType === 'Booked' ? 1 : 0,
        }))
        .reduce<Record<string, { calls: number; bookings: number }>>((acc, curr) => {
          if (!acc[curr.hour]) {
            acc[curr.hour] = { calls: 0, bookings: 0 };
          }
          acc[curr.hour].calls += curr.callsPerHour;
          acc[curr.hour].bookings += curr.bookingsPerHour;
          return acc;
        }, {}) ?? []
    ).map(([hour, { calls, bookings }]) => ({
      hour,
      calls,
      bookings,
      bookingRate: calls > 0 ? parseFloat(((bookings / calls) * 100).toFixed(1)) : 0,
    }));
  };

  const todayData = getHourlyData(dayjs());
  const yesterdayData = getHourlyData(dayjs().subtract(1, 'day'));

  const currentHourActions = leaderboardData?.currentUser?.userActions?.filter((action) => dayjs(action.when).isSame(dayjs(), 'hour'));

  const actionTypes = ['Booked', 'ReachedNotBooked', 'NotReached', 'CustomerLost', 'WrongPhoneNumber'];
  const userActionCount =
    leaderboardData?.currentUser?.userActions.filter((action) => dayjs(action.when).isSame(dayjs(), 'day')).filter((action) => actionTypes.includes(action.actionType)).length ?? 0;

  const calculateBookingsDiff = () => {
    // accumulate bookings till current hour for yesterday and today and compare
    const currentHour = dayjs().format('H');
    const accumulatedBookingsToday = todayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.bookings, 0);
    const accumulatedBookingsYesterday = yesterdayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.bookings, 0);

    return accumulatedBookingsToday - accumulatedBookingsYesterday;
  };

  const calculateCallsDiff = () => {
    const currentHour = dayjs().format('H');
    const accumulatedCallsToday = todayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.calls, 0);
    const accumulatedcallsYesterday = yesterdayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.calls, 0);

    return accumulatedCallsToday - accumulatedcallsYesterday;
  };

  const calculateBookingsRateDiff = () => {
    const currentHour = dayjs().format('H');
    const accumulatedBookingsToday = todayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.bookings, 0);
    const accumulatedBookingsYesterday = yesterdayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.bookings, 0);
    const accumulatedCallsToday = todayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.calls, 0);
    const accumulatedcallsYesterday = yesterdayData.filter((item) => parseInt(item.hour) <= parseInt(currentHour)).reduce((acc, curr) => acc + curr.calls, 0);

    const bookingRateToday = accumulatedCallsToday > 0 ? (accumulatedBookingsToday / accumulatedCallsToday) * 100 : 0;
    const bookingRateYesterday = accumulatedcallsYesterday > 0 ? (accumulatedBookingsYesterday / accumulatedcallsYesterday) * 100 : 0;

    return bookingRateToday - bookingRateYesterday;
  };

  const getNextUpdateTimeForShowRate = () => {
    const utcNow = dayjs().utc();
    const sortedSlot = leaderboardData?.currentUser?.slotsForToday
      ?.sort((a, b) => dayjs(a.endTime).diff(dayjs(b.endTime)))
      .map((entry) => ({
        ...entry,
        formattedTime: dayjs(entry.endTime).format('HH:mm'),
      }))
      .find((entry) => dayjs(entry.endTime).isAfter(utcNow));
    return sortedSlot?.formattedTime ? `${strings.reportingNextUpdateAt} ${sortedSlot.formattedTime}` : strings.reportingNoAppointmentsToday;
  };

  const getShowRateClarification = () => {
    const utcNow = dayjs().utc();
    const consideredAppointments = leaderboardData?.currentUser?.slotsForToday.filter((entry) => dayjs(entry.endTime).isBefore(utcNow)).length;
    const appointmentsAfter = leaderboardData?.currentUser?.slotsForToday.filter((entry) => dayjs(entry.endTime).isAfter(utcNow)).length;

    return `${strings.reportingAppointmentsConsidered}: ${consideredAppointments}
    ${strings.reportingAppointmentsToGo}: ${appointmentsAfter}`;
  };

  const calculateShowRateForUser = () => {
    if (leaderboardData?.currentUser?.slotsForToday.length === 0 || leaderboardData?.currentUser?.slotsForToday.filter((entry) => dayjs(entry.endTime).isBefore(dayjs().utc())).length === 0) {
      return '-';
    } else {
      const count =
        (leaderboardData?.currentUser?.slotsForToday.filter((entry) => dayjs(entry.endTime).isBefore(dayjs().utc()) && entry.status === 'Completed').length! /
          leaderboardData?.currentUser?.slotsForToday.filter((entry) => dayjs(entry.endTime).isBefore(dayjs().utc())).length!) *
        100;
      return Number.isInteger(count) ? count : count.toFixed(1);
    }
  };

  const convertDataForShowOrBookingRateCard = (ratingDto: RatingDto | null) => {
    return ratingDto
      ? {
          currentUserPosition: ratingDto?.currentUserPosition,
          currentUserCriteriaCount: !Number.isInteger(ratingDto?.currentUserCriteriaCount)
            ? parseFloat((ratingDto?.currentUserCriteriaCount! * 100).toFixed(1))
            : ratingDto?.currentUserCriteriaCount! * 100,
          leaderboard: ratingDto?.leaderboard
            .sort((a, b) => b.count - a.count)
            .map((booker) => ({
              ...booker,
              count: Number.isInteger(booker.count) ? booker.count * 100 : parseFloat((booker.count * 100).toFixed(1)),
            })),
        }
      : null;
  };

  return (
    <>
      <Header />
      <div className="activity-page">
        <h3 className="activity-title">{strings.reportingActivity}</h3>
        <Card className="activity-card" style={{ paddingLeft: '5px', paddingRight: '30px' }}>
          {currentHourActions && currentHourActions?.filter((action) => action.actionType === 'WentOffline').length >= 3 && (
            <section className="warning-section">
              <div className="warning-message">
                <ExclamationOutlined style={{ color: 'red', paddingLeft: '5px', paddingRight: '10px' }} />
                {strings.reportingWarningMessage}
              </div>
            </section>
          )}
          <Row gutter={16}>
            <Col span={6} style={{ paddingLeft: '25px' }}>
              <Statistic
                title={<b>{strings.reportingBookings}</b>}
                value={leaderboardData?.topBookers.currentUserCriteriaCount}
                valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
              />
              <span>
                {calculateBookingsDiff() > 0 ? (
                  <CaretUpOutlined style={{ color: 'green' }} />
                ) : calculateBookingsDiff() < 0 ? (
                  <CaretDownOutlined style={{ color: 'red' }} />
                ) : (
                  <MinusOutlined style={{ color: 'gray' }} />
                )}
                {calculateBookingsDiff()} {strings.reportingVsYesterday}{' '}
                <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                  {' '}
                  <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                </Tooltip>
              </span>
            </Col>
            <Col span={6} style={{ paddingLeft: '57px' }}>
              <Statistic title={<b>{strings.reportingCalls}</b>} value={userActionCount} valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }} />

              <span>
                {calculateCallsDiff() > 0 ? (
                  <CaretUpOutlined style={{ color: 'green' }} />
                ) : calculateCallsDiff() < 0 ? (
                  <CaretDownOutlined style={{ color: 'red' }} />
                ) : (
                  <MinusOutlined style={{ color: 'gray' }} />
                )}
                {calculateCallsDiff()} {strings.reportingVsYesterday}{' '}
                <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                  {' '}
                  <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                </Tooltip>
              </span>
            </Col>
            <Col span={6} style={{ paddingLeft: '85px' }}>
              <Statistic
                title={<b>{strings.reportingBookingRate}</b>}
                value={
                  leaderboardData?.highestBookingRate.currentUserCriteriaCount === null
                    ? 0
                    : Number.isInteger(leaderboardData?.highestBookingRate.currentUserCriteriaCount! * 100)
                    ? leaderboardData?.highestBookingRate.currentUserCriteriaCount! * 100
                    : (leaderboardData?.highestBookingRate.currentUserCriteriaCount! * 100).toFixed(1)
                }
                suffix="%"
                valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
              />
              <span>
                {calculateBookingsRateDiff() > 0 ? (
                  <CaretUpOutlined style={{ color: 'green' }} />
                ) : calculateBookingsRateDiff() < 0 ? (
                  <CaretDownOutlined style={{ color: 'red' }} />
                ) : (
                  <MinusOutlined style={{ color: 'gray' }} />
                )}
                {calculateBookingsRateDiff().toFixed(1)}% {strings.reportingVsYesterday}{' '}
                <Tooltip title={strings.reportingComparedToSameTimeYesterday}>
                  {' '}
                  <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                </Tooltip>
              </span>
            </Col>
            <Col span={6} style={{ paddingLeft: '85px' }}>
              <Statistic
                title={<b>{strings.reportingShowRate}</b>}
                value={calculateShowRateForUser()}
                suffix={leaderboardData?.currentUser?.slotsForToday.filter((entry) => dayjs(entry.endTime).isBefore(dayjs().utc())).length === 0 ? '' : '%'}
                valueStyle={{ fontWeight: '600', fontSize: '48px', lineHeight: '1', marginBottom: '10px' }}
              />
              <span>
                {getNextUpdateTimeForShowRate()}
                <Tooltip title={getShowRateClarification()}>
                  {' '}
                  <InfoCircleOutlined style={{ color: 'grey', marginLeft: '2px' }} />
                </Tooltip>
              </span>
            </Col>
            <Col span={24}>
              <Title level={5} style={{ marginBottom: '20px', marginLeft: '20px' }}>
                {strings.reportingCallPerHour}
              </Title>
              <LineChart h={300} data={data} dataKey="hour" series={[{ name: 'callsPerHour', color: 'blue.6', label: 'Calls per Hour' }]} curveType="linear" />
            </Col>
          </Row>
        </Card>
        <Row gutter={16} className="ranking-section">
          <Col span={6}>
            <RankingCard title={strings.reportingTopBookersToday} rankData={leaderboardData?.topBookers ?? null} />
          </Col>
          <Col span={6}>
            <RankingCard title={strings.reportingTopCallersToday} rankData={leaderboardData?.topCallers ?? null} />
          </Col>
          <Col span={6}>
            <RankingCard title={strings.reportingHighestBookingRateToday} rankData={convertDataForShowOrBookingRateCard(leaderboardData?.highestBookingRate ?? null)} />
          </Col>
          <Tooltip title={strings.reportingShowRateClarification}>
            <Col span={6}>
              <RankingCard title={strings.reportingShowRateLeaderboard} rankData={convertDataForShowOrBookingRateCard(leaderboardData?.topShowRate ?? null)} />
            </Col>
          </Tooltip>
        </Row>
      </div>
    </>
  );
};

export default ReportingPage;
