import "./utils/datadog";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then((_) => {
  root.render(
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>,
  );
});
