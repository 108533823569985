import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { strings } from "../../lang";
import "./sales-call-calendar.css";

dayjs.extend(isoWeek);

export interface CalendarHeaderProps {
  calendarWeek: number;
  calendarYear: number;
  selectedDay: number;
}

export const CalendarHeader = ({
  calendarWeek,
  calendarYear,
  selectedDay,
}: CalendarHeaderProps) => {
  if (dayjs().add(1, 'year').year() == calendarYear) {
    calendarYear = calendarYear - 1;
  }
  const week = dayjs().year(calendarYear).isoWeek(calendarWeek);
  const monday = week.startOf("isoWeek");
  return (
    <div className="calendar-header">
      {
        // This array represent the IsoWeekDays of Monday (1) till Saturday (6).
        [1, 2, 3, 4, 5, 6].map((isoDayOfWeek) => {
          const day = monday.add(isoDayOfWeek - 1, "day");

          return (
            <div
              key={isoDayOfWeek}
              className={`calendar-header__day ${selectedDay === isoDayOfWeek
                ? "calendar-header__day--selected"
                : ""
                }`}
            >
              <div>
                <div className="calendar-header__weekDay">
                  {(strings as any)[day.format("dd")]}
                </div>
                <div className="calendar-header__date">
                  {day.format("DD.MM")}
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};
